<template>
  <b-field v-if="!isHidden" :label="`Last audit date ${required ? '*' : ''}`">
    <date-time-picker
      :value="date ? $moment(date).format('YYYY-MM-DD HH:mm') : ''"
      :disabled="disabled"
      readonly
      format="YYYY-MM-DD HH:mm"
      :show-button="true"
      @input="$emit('change', $event)"
    />
  </b-field>
</template>

<script>
export default {
  name: "SiteLastAuditField",
  props: {
    date: {
      type: Date,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isHidden: false
    };
  }
};
</script>
